body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  border-radius: 4px;
  background: #4f39b5;
  margin: 0 auto;
  max-width: 500px;
  position: absolute;
  right: 10%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 25px;
}

.MuiButton-contained,
.MuiButton-containedPrimary {
  color: #4f39b5 !important;
  background-color: #a2ee0f !important;
  font-size: 20px !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold !important;
}
